import React, { useState, useEffect, useRef } from "react";
import style from "./Dashboard.module.css";
import { IoPlay } from "react-icons/io5";
import welcomeImage from "../../assests/Group 34.png";
import { FaArrowRightLong } from "react-icons/fa6";
import ActiveCalendar from "../../assests/activeCalendar.png";
import ActiveWorkOut from "../../assests/ActiveWeightlifting-sport-exercise-gym (1).png";
import Calendar from "../../assests/Calendar.png";
import WorkOut from "../../assests/Weightlifting-sport-exercise-gym.png";
import Chart from "chart.js/auto";
import { useNavigate } from "react-router-dom";
import { baseurl } from "../Url";
import Loader from "../Loder/Loader";
import TimerImage from "../../assests/Chronometer.png";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const navigate=useNavigate()
 
  const UserName=localStorage.getItem("username")
  const key = JSON.parse(localStorage.getItem("access-token"));
  const UserID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);

  const [learningData, setLearningData] = useState({});
  const [loading, setLoading] = useState(true);
  const [dailYDrill, setDailyDrill] = useState(false);
  const [weeklyWorkout, setWeeklyWorkout] = useState(false);
  const [snoozePopup,setSnoozePopup]=useState(false)
  const [result,setResult]=useState({})
  const [activePopup, setActivePopup] = useState(null);
  const [videoPopup,setVideoPopup]=useState(false)

  const totalModules = learningData.Topics ? learningData.Topics.length : 0;
  const totalTopics = learningData.Topics
    ? learningData.Topics.reduce((acc, module) => acc + module.topics.length, 0)
    : 0;
  const completionPercentage = learningData.percentage_completion ? learningData.percentage_completion : 1;

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();

  const handleSnooze = (minutes) => {
    if (activePopup === 'dailyDrill') {
      snoozeCreate(minutes,"daily");
    } else if (activePopup === 'weeklyWorkout') {
      snoozeCreate(minutes,"weekly");
    }
   
  };
  


  async function learningList() {
    await fetch(`${baseurl}/userlearning/details`, {
      method: "POST",
      body: JSON.stringify({ user_id: UserID }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLearningData(data.data[0]);
          setTimeout(() => {
            setLoading(false)
          }, 1000);
        }
      });
  }

  async function dailyWeeklyExam(Type) {
     const type=Type?Type:"daily"
    await fetch(`${baseurl}/check/daily/weakly/exams`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: UserID,
        date: currentDate,
        type: type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setResult(data.data[0])

          if(Type&&Type==="daily"&&data.data[0]?.Daily_Drill?.DailyDrill === true && data.data[0]?.Daily_Drill?.snoozed === false){
            setDailyDrill(true)
          }
          if(Type&&Type==="weekly"&&data.data[0]?.WeeklyDrill?.WeeklyDrill === true && data.data[0]?.WeeklyDrill?.snoozed === false){
            setWeeklyWorkout(true)
          }
          

          // if (data.data[0]?.Daily_Drill?.DailyDrill === true && data.data[0]?.Daily_Drill?.snoozed === false &&!JSON.parse(localStorage.getItem('hasShownDailyDrill'))) {
          //   setDailyDrill(true);
          //   localStorage.setItem('hasShownDailyDrill',true);
          // }
         
            if (data.data[0]?.Daily_Drill?.DailyDrill === true && data.data[0]?.Daily_Drill?.snoozed === false &&JSON.parse(localStorage.getItem('dailySnoozeTriggered'))) {
              setDailyDrill(true);
              localStorage.removeItem('dailySnoozeTriggered');
            }
          
  
            if (data.data[0]?.WeeklyDrill?.WeeklyDrill === true && data.data[0]?.WeeklyDrill?.snoozed === false && JSON.parse(localStorage.getItem('weeklySnoozeTriggered'))) {
              setWeeklyWorkout(true);
              localStorage.removeItem('weeklySnoozeTriggered');
              
            
          }
        }
      });
  }

  async function snoozeCreate(min,type) {
    await fetch(`${baseurl}/snooze/create`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: UserID,
        snooze_min: min.toString(),
        exam_type: type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setSnoozePopup(false);
          setActivePopup(null); 
          dailyWeeklyExam();
          if (type === "daily") {
            localStorage.setItem('dailySnoozeTriggered', true);
          } else if (type === "weekly") {
            localStorage.setItem('weeklySnoozeTriggered', true);
          }
        }
      });
  }

  useEffect(() => {
    if (UserID) {
      learningList();
      dailyWeeklyExam()
    }
  }, [UserID]);

  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    // Create new chart instance
    if (chartContainer.current) {
      chartInstance.current = new Chart(chartContainer.current, {
        type: "doughnut",
        data: {
          datasets: [
            {
              label: "Completion",
              data: [completionPercentage, 100 - completionPercentage],
              backgroundColor: ["#595BDB", "#FFF"],
            },
          ],
        },
        options: {
          responsive: true,
          cutout: "85%",
          plugins: {
            legend: {
              position: "top",
            },
            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }

    // Cleanup function
    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
      }
    };
  }, [completionPercentage]);

  useEffect(() => {
    const interval = setInterval(() => {
      dailyWeeklyExam();
    }, 60000); 
  
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={style.container}>
      {loading?<Loader/>:""}
      <div className={style.dashboardSection}>
        <div className={style.welcomeSection}>
          <div className={style.welcomeSectionLeft}>
            <p>Hello {userStatus === 'succeeded' && userDetails &&userDetails.Username}!</p>
            <span>Welcome to English Wagon</span>
          </div>
          <div className={style.welcomeSectionRight}>
            <div className={style.welcomeImageContainer}>
              <div className={style.welcomeImage}>
                <img src={welcomeImage} alt="welcome" />
              </div>
            </div>
          </div>
        </div>
        <div className={style.videoSection}>
          <div className={style.videoSectionLeft}>
            <p>How {learningData?.category_details?learningData.category_details:"GrammarLocker"} Works?</p>
          </div>
          <div className={style.videoSectionRight}>
            <div onClick={()=>{
              setVideoPopup(true)
            }} className={style.videoButtonSection}>
              <IoPlay /> <p>Watch Now</p>
            </div>
          </div>
        </div>
        <div className={style.activitySection}>
          <div className={style.activitySectionLeft}>
            <div className={style.activitySectionLeftTop}>
              <h4>{learningData?.category_details?learningData.category_details:"GrammarLocker"}</h4>
              <p>
                The ultimate online, interactive English grammar workbook. An
                interactive way to bridge your knowledge gaps and transform you
                into a confident and accurate communicator.
              </p>
            </div>
            <div className={style.activitySectionLeftBottom}>
              <div className={style.moduleText}>
                <p>{totalModules}</p>
                <span>Modules</span>
              </div>
              <div className={style.moduleText}>
                <p>{totalTopics}</p>
                <span>Topics</span>
              </div>
              <div className={style.exploreButton}>
                <p>Explore More</p>
                <FaArrowRightLong />
              </div>
            </div>
          </div>
          <div className={style.activitySectionRight}>
            <div className={style.activityRightHeader}>
              {/* <p>Activities</p> {result?.Daily_Drill?.last_attend_date!=="0"?<span>You are {result?.Daily_Drill?.last_attend_date} day overdue</span>:""} */}
            </div>
            <div className={style.activityCardContainer}>
              <div
                onClick={() => {
                    dailyWeeklyExam("daily")
                }}
                className={`${style.activityCard} 
              ${result?.Daily_Drill?.DailyDrill === false || result?.Daily_Drill?.snoozed === true 
                ? style.disabledCard 
                : style.dailyCard} 
              `}
              >
                <div className={style.activityCardImage}>
                  {result?.Daily_Drill?.DailyDrill === false || result?.Daily_Drill?.snoozed === true?
                  <img src={Calendar} alt="calendar" />
                  :<img src={ActiveCalendar} alt="calendar" />}
                </div>
                <div className={style.activityCardText}>
                  <p>Daily drill</p>
                </div>
              </div>
              <div
                className={`${style.activityCard} ${result?.WeeklyDrill?.WeeklyDrill === false || result?.WeeklyDrill?.snoozed === true?style.disabledCard:style.weeklyCard}`}
                onClick={() => {
                  
                    dailyWeeklyExam("weekly");
                  
                }} 
              >
                <div className={style.activityCardImage}>
                {result?.WeeklyDrill?.WeeklyDrill === false || result?.WeeklyDrill?.snoozed === true?
                  <img src={WorkOut} alt="calendar" />
                  :<img src={ActiveWorkOut} alt="calendar" />}
                </div>
                <div className={style.activityCardText}>
                  <p>Weekly workout</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.statusSection}>
          <div className={style.statusSectionLeft}>
            <div className={style.statusHeading}>
              <p>Current Status</p>
            </div>
            <div className={style.moduleHeading}>
              <p>{learningData.Topics && learningData.Topics[0] ? learningData.Topics[0].module_name : 'Module 1: Sentences'}</p>
            </div>
            <div
              className={style.progressBar}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "2rem",
              }}
            >
              <div
                style={{
                  width: `${learningData.percentage_completion?learningData.percentage_completion:1}%`,
                  backgroundColor: "#595BDB",
                  height: "8px",
                  transition: "width 0.5s ease-in-out",
                  borderRadius: "1rem",
                }}
              ></div>
            </div>
            <div className={style.topicContainer}>
            {learningData.Topics && learningData.Topics.length > 0&&
            learningData.Topics[0].topics.map((topic, topicIndex) => (
                <div key={topicIndex} className={style.topicCard}>
                  <div className={style.topicCardLeft}>
                    <p>{topic.value}</p>
                  </div>
                  <div className={style.topicCardRight}>
                    <p style={{ color: topic.status === 'complete' ? '#00DFC7' : topic.status === 'In Progress' ? '#FFBE00' : '#D9D9D9' }}>
                    {topic.status === 'complete' ? 'Complete' : topic.status === 'In Progress' ? 'In Progress' : 'Upcoming Topics'}
                    </p>
                  </div>
                </div>
            )
            )}
            </div>
          </div>
          <div className={style.statusSectionRight}>
            <div className={style.statusSectionRightContent}>
              <div className={style.statusRightHeading}>
                <p>Performance</p>
                <span>% of marks acquired in Module 1</span>
              </div>
              <div className={style.statusRightBody}>
                <h4>Module 1</h4>
                <span>{`Topic ${
          learningData.Topics && learningData.Topics.length > 0
            ? `${learningData.Topics[0].topics.filter(t => t.status === 'In Progress').length}/${learningData.Topics[0].topics.length}`
            : '0/0'
        }`}</span>
              </div>
              <div className={style.statusRightButton}>
                <p>Explore more</p>
              </div>
            </div>
            <div className={style.statusSectionRightGraph}>
              <canvas ref={chartContainer} />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "16px",
                  color: "#5F5F5F",
                  pointerEvents: "none",
                }}
              >
                {learningData.percentage_completion
                  ? Math.round(learningData.percentage_completion)
                  : 0}
                %
              </div>
            </div>
          </div>
        </div>
      </div>
      {dailYDrill ? (
        <>
          <div className={style.popupOverlay} onClick={()=>{
            setDailyDrill(false)
          }}></div>
          <div className={style.Popup}>
            <div className={style.popupHeader}>
              <div className={style.popupHeaderIcon}>
                <img src={ActiveCalendar} alt="calendar" />
              </div>
              <div className={style.popupMainHeader}>
                <p>Daily Drill</p>
              </div>
              <div className={style.popupSubHeader}>
                <p>
                  Sharpen your skills daily with quick, focused exercises.
                  <br />
                  Practice makes perfect!
                </p>
              </div>
            </div>
            <div className={style.popupBody}>
            <div className={style.popupBodyTop}>
            <div className={style.popupBodyCard}>
              <span>{result?.Daily_Drill?.no_of_questions}</span><p>Questions</p>
            </div>
            <div className={style.popupBodyCard}>
              <img src={TimerImage} alt="timer"/><p>{result?.Daily_Drill?.alloted_time} mins</p>
            </div>
            </div>
            <div className={style.popupBodyBottom}>
              <p>Are you ready to take the daily drill?</p>
            </div>
            </div>
            <div className={style.PopupButton}>
              <button className={style.snoozeButton} onClick={() => {
                setDailyDrill(false);
                setActivePopup('dailyDrill');
                setSnoozePopup(true);
              }}>
                Snooze
              </button>
              <button className={style.yesButton} onClick={() => {
                navigate("/daily/drill")
              }}>
                Yes
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {weeklyWorkout ? (
        <>
          <div className={style.popupOverlay}  onClick={()=>{
            setWeeklyWorkout(false)
          }}></div>
          <div className={style.Popup}>
            <div className={style.popupHeader}>
              <div className={style.popupHeaderIcon}>
                <img src={ActiveWorkOut} alt="calendar" />
              </div>
              <div className={style.popupMainHeader}>
                <p>Weekly Workout</p>
              </div>
              <div className={style.popupSubHeader}>
                <p>
                  Sharpen your skills daily with quick, focused exercises.
                  <br />
                  Practice makes perfect!
                </p>
              </div>
            </div>
            <div className={style.popupBody}>
            <div className={style.popupBodyTop}>
            <div className={style.popupBodyCard}>
              <span>{result?.WeeklyDrill?.no_of_questions}</span><p>Questions</p>
            </div>
            <div className={style.popupBodyCard}>
              <img src={TimerImage} alt="timer"/><p>{result?.WeeklyDrill?.alloted_time} mins</p>
            </div>
            </div>
            <div className={style.popupBodyBottom}>
              <p>Are you ready to take the weekly workout?</p>
            </div>
            </div>
            <div className={style.PopupButton}>
              <button className={style.snoozeButton} onClick={() => {
                setWeeklyWorkout(false);
                setActivePopup('weeklyWorkout');
                setSnoozePopup(true);
              }}>
                Snooze
              </button>
              <button style={{background:"#00DFC7"}} className={style.yesButton} onClick={() => {
                navigate("/weekly/workout")
              }}>
                Yes
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {snoozePopup ? (
        <>
          <div className={style.popupOverlay}  onClick={()=>{
            setSnoozePopup(false)
            setActivePopup(null)
          }}></div>
          <div className={style.Popup}>
          <div className={style.snoozeHeader}>
            <p>When should we remind you for the {activePopup === 'dailyDrill' ? 'Daily Drill' : 'Weekly Workout'}?</p>
          </div>
            <div className={style.snoozeContainer}>
            <div onClick={() => handleSnooze(5)} className={style.snoozeCard}>
              <p>In 5 min</p>
            </div>
            <div onClick={() => handleSnooze(10)} className={style.snoozeCard}>
              <p>In 10 min</p>
            </div>
            <div onClick={() => handleSnooze(15)} className={style.snoozeCard}>
              <p>In 15 min</p>
            </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {videoPopup&&(
        <>
        <div className={style.popupOverlay}></div>
        <div className={style.videoPopup}>
        <div className={style.videoContainer}>
          <div className={style.videoClose}>
            <AiOutlineClose onClick={()=>setVideoPopup(false)}/>
          </div>
          <video controls>
                          <source src="https://firebasestorage.googleapis.com/v0/b/oculusinstaller.appspot.com/o/demo.mp4?alt=media&token=aa5f2941-28d1-4681-868f-a5b173b1d892" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
        </div>
        </div>
        </>
      )}

    </div>
  );
};

export default Dashboard;
