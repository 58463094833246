import React,{useState,useMemo,useEffect} from 'react'
import style from "./Performance.module.css"
import performanceImage from "../../assests/hand-drawn-neuroeducation-illustration 4.png"
import ActiveCalendar from "../../assests/activeCalendar.png";
import ActiveWorkOut from "../../assests/ActiveWeightlifting-sport-exercise-gym (1).png";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import { baseurl } from "../Url";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



const Performance = () => {

  const key = JSON.parse(localStorage.getItem("access-token"));
  const UserID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  const [mainData,setMainData]=useState({})
  const [roundGraphData,setRoundGraphData]=useState([])
  const [learningPaceData,setLearningPaceData]=useState({})
  const [learningLevelData,setLearningLevelData]=useState({})
  const [moduleList,setModuleList]=useState([]);
  const [learningLevelModID,setLearningLevelModID]=useState("");
  const [learningPaceModID,setLearningPaceModID]=useState("");
  const [moduleProgress,setModuleProgress]=useState(0)

  const sampleGraph = [
    { topic_no: 1, percentage: 75, mark: 12, lat_mark: 61 },
    { topic_no: 2, percentage: 55, mark: 9 , lat_mark: 37},
    { topic_no: 3, percentage: 89, mark: 14 , lat_mark: 46},
    { topic_no: 4, percentage: 45, mark: 8 , lat_mark: 11},
    { topic_no: 5, percentage: 25, mark: 5, lat_mark: 12 },
    { topic_no: 6, percentage: 45, mark: 8, lat_mark: 24 },
    { topic_no: 7, percentage: 25, mark: 5, lat_mark: 14 },
    { topic_no: 8, percentage: 0, mark: 0 , lat_mark: 0},
    { topic_no: 9, percentage: 0, mark: 0, lat_mark: 0 },
  ];
    const [selectedModule, setSelectedModule] = useState(0);
    const [selectedDate, setSelectedDate] = useState("W");

    const [dailyDrillData,setDailyDrillData]=useState([])
    const [weeklyWorkoutData,setWeeklyWorkoutData]=useState([])
    const [dateForDD, setDateForDD] = useState("week");
    const [dateForWW, setDateForWW] = useState("week");
    const [dateForLP, setDateForLP] = useState("weekly");

    const handleDDDate =(date)=>{
      setDateForDD(date)
      dailyDrillGraph(date);
    }

    const handleWWDate =(date)=>{
      setDateForWW(date)
      weeklyWorkoutGraph(date);
    }
    const handleLPDate =(date)=>{
      setDateForLP(date)
     
    }

    const getColorBasedOnPercentage = (percentage) => {
      if (percentage > 75) return "#03CD09";  
      if (percentage > 50) return "#FFCB34";   
      if (percentage > 25) return "#FF8800";   
      return "#FF2443";                         
    };

    const { labels, data } = useMemo(() => {
      const today = dayjs();
      let labels = [];
      let data = Array(9).fill(0); // Assuming 9 topics
  
      if (selectedDate === "W") {
        labels = Array.from({ length: 7 }, (_, i) => (today.startOf('week').add(i, 'day').format('DD')));
  
        labels.forEach((_, i) => {
          data[i % 9] = Math.floor(Math.random() * 10); // Random attendance data
        });
      } else if (selectedDate === "M") {
        const daysInMonth = today.daysInMonth();
        labels = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
  
        labels.forEach((_, i) => {
          data[i % 9] = Math.floor(Math.random() * 10); // Random attendance data
        });
      }
  
      return { labels, data };
    }, [selectedDate]);
  
    const chartData = {
      labels,
      datasets: [
        {
          label: 'Topic Attendance',
          data: data,
          backgroundColor: '#FF8800',
          borderWidth: 0,
          barPercentage: 0.2,
          borderRadius: 5,
        },
      ],
    };
   //second bar
    const topicLabels = sampleGraph.map(item => ` ${item.topic_no}`);
  const scoreData = sampleGraph.map(item => item.percentage);

  const learningSectionRightData = {
    labels: topicLabels,
    datasets: [
      {
        label: 'Topic Scores',
        data: scoreData,
        backgroundColor: '#FFBE00',
        borderWidth: 0,
        barPercentage: 0.5,
        categoryPercentage: 0.8,
        borderRadius: 5,
      },
    ],
  };

  const dailyLineLabels = dailyDrillData.map((item) => item["x-axis"].substring(8, 10)); // Extracts DD from YYYY-MM-DD
  const dailyLineScores = dailyDrillData.map((item) => item["y-axis"]);
  const ddCorrectScores = dailyDrillData.map((item) => item["correct"]); // Array of correct scores
  const ddTimeInSeconds = dailyDrillData.map((item) => item["time_sec"]);
  

  const lineChartData = {
    labels: dailyLineLabels, 
    datasets: [
      {
        label: 'Today Scores',
        data: dailyLineScores,
        borderColor: '#595BDB',
        backgroundColor: 'rgba(89, 91, 219, 0.2)',
        fill: true,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: '#595BDB',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };

  const weeklyLineLabels = weeklyWorkoutData.map((item) => item["x-axis"].substring(8, 10)); // Extracts DD from YYYY-MM-DD
  const weeklyLineScores = weeklyWorkoutData.map((item) => item["y-axis"]);
  const wwCorrectScores = weeklyWorkoutData.map((item) => item["correct"]); // Array of correct scores
  const wwTimeInSeconds = weeklyWorkoutData.map((item) => item["time_sec"]);

  const weeklyLineChart = {
    labels: weeklyLineLabels,
    datasets: [
      {
        label: 'Weekly Scores',
        data: weeklyLineScores,
        borderColor: '#29bcd6',
        backgroundColor: '#7adef0',
        fill: true,
        tension: 0.3, 
        borderWidth: 2,
        pointBackgroundColor: '#29bcd6',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };
  

  const learningPaceLabels = learningPaceData?.data?.map(item => dayjs(item.period).format('DD')); // Extracting periods for x-axis
const topicCounts =learningPaceData?.data?.map(item => item.topic_count); // Extracting topic counts for y-axis

const learningPaceChartData = {
    labels: learningPaceLabels,
    datasets: [
        {
            label: 'Topic Count',
            data: topicCounts,
            backgroundColor: '#FF8800', // Set your desired color
            barThickness: 30 // Adjust bar width if needed
        }
    ]
};

const learningPaceOptions = {
  responsive: true,
  plugins: {
      legend: {
          display: false,
      },
      tooltip: {
          callbacks: {
              label: function (context) {
                  return `Topic ${context.dataIndex + 1}: ${context.raw}`;
              },
          },
      },
  },
  scales: {
      x: {
          title: {
              display: true,
              text: `Day (${dayjs().format('MMM')})`,
          },
          grid: {
              display: false,
          },
          beginAtZero: false,
          ticks: {
              stepSize: 1,
          },
      },
      y: {
          title: {
              display: true,
              text: 'Topics',
          },
          beginAtZero: true,
          max: learningPaceData.module_topics_count, 
          ticks: {
              stepSize: 1, 
              callback: function(value) {
                  return value; 
              },
          },
      },
  },
};

const learningLevelGraphData = {
  labels: [...Array(learningLevelData?.module_topics_count).keys()], 
  datasets: [
    {
      label: 'Scores',
      data: learningLevelData?.data?.map((topicArray) => parseFloat(topicArray[0].score)), 
      backgroundColor: '#FF8800',
      barThickness: 30,
    },
  ],
};
const learningLevelOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return `Topic ${context.dataIndex + 1}: ${context.raw}%`;
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Topics',
      },
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
        callback: function (value) {
          return value + 1; // Display topic numbers starting from 1
        },
      },
    },
    y: {
      title: {
        display: true,
        text: 'Score (%)',
      },
      beginAtZero: true,
      max: 100, // Assuming score is out of 100
      ticks: {
        stepSize: 20,
        callback: function (value) {
          return value + '%'; // Show percentage on y-axis
        },
      },
    },
  },
};
  


  async function mainAnalytics() {
    await fetch(`${baseurl}/performance/analytics/main`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        org_id:OrgID
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setMainData(data.data[0])
         const initialModuleId=data.data[0]?.Modules[0].module_id
         topicAnalytics(initialModuleId)
         setLearningPaceModID(initialModuleId)
         setLearningLevelModID(initialModuleId)
         setModuleList(data.data[0]?.Modules)
        }
        else{
          setMainData({})
        }
      });
  }

  async function topicAnalytics(modID) {
    await fetch(`${baseurl}/performance/module-topic-summary`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        module_id:modID
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setRoundGraphData(data.data)
         setModuleProgress(data.module_progress)
        }
        else{
          setRoundGraphData([])
          setModuleProgress(0)
        }
      });
  }
  async function learningPace(modID,date) {
    await fetch(`${baseurl}/performance/topic-count`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        module_id:modID,
        filter: date
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setLearningPaceData(data)
        }
        else{
          setLearningPaceData({})
        }
      });
  }
  async function learningLevel(modID,date) {
    await fetch(`${baseurl}/performance/learning-level`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        module_id:modID,
        filter: date
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setLearningLevelData(data)
        }
        else{
          setLearningLevelData()
        }
      });
  }

  async function dailyDrillGraph(type) {
    await fetch(`${baseurl}/analytics/scores/daily`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        filter_type:type
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setDailyDrillData(data.data)
        }
        else{
          setDailyDrillData([])
        }
      });
  }
  async function weeklyWorkoutGraph(type) {
    await fetch(`${baseurl}/analytics/scores/weekly`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        filter_type:type
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setWeeklyWorkoutData(data.data)
        }
        else{
          setWeeklyWorkoutData([])
        }
      });
  }

  useEffect(()=>{
    if(key){
      mainAnalytics();
      dailyDrillGraph("week");
      weeklyWorkoutGraph("week");
    }
  },[key])

  useEffect(()=>{
    learningPace(learningPaceModID,dateForLP)
  },[learningPaceModID,dateForLP])

  useEffect(()=>{
    learningLevel(learningLevelModID)
  },[learningLevelModID])


  return (
    <div className={style.container}>
        <div className={style.performance}>
        <div className={style.welcomeSection}>
          <div className={style.welcomeSectionLeft}>
            <p>Performance</p>
            <span>Take a look at your subscription plan.</span>
          </div>
          <div className={style.welcomeSectionRight}>
            <div className={style.welcomeImageContainer}>
              <div className={style.welcomeImage}>
                <img src={performanceImage} alt="welcome" />
              </div>
            </div>
          </div>
        </div>

        <div className={style.assessmentSection}>
            <div className={style.dailyDrillCard}>
            <div className={style.assessmentCardLeft}>
                <div className={style.assessmentCardImage}>
                    <img src={ActiveCalendar} alt='dailyDrill'/>
                </div>
            </div>
            <div className={style.assessmentCardRight}>
                <p>You’ve completed </p>
                <h4>{mainData.Completed_Drills?.completed_daily||0}</h4>
                <span>Daily drills </span>
            </div>
            </div>

            <div className={style.weeklyWorkOutCard}>
            <div className={style.assessmentCardLeft}>
                <div className={style.assessmentCardImage}>
                    <img src={ActiveWorkOut} alt='weeklyWorkout'/>
                </div>
            </div>
            <div className={style.assessmentCardRight}>
                <p>You’ve completed </p>
                <h4>{mainData.Completed_Drills?.completed_weekly||0}</h4>
                <span>Weekly Workouts </span>
            </div>
            </div>
        </div>

        <div className={style.moduleSection}>
            <div className={style.moduleHeder}>
                <p>Modules</p>
            </div>
            <div className={style.numberBarSection}>
                <div className={style.numberBar}>
                  {mainData.Modules&&mainData.Modules.length>0&&mainData.Modules.map((module, index) => (
                    <div
                      key={module.module_id}
                      className={
                        selectedModule === index
                          ? style.selectedNumber
                          : style.number
                      }
                      onClick={() => {
                        setSelectedModule(index)
                        topicAnalytics(module.module_id)
                      }}
                    >
                      {index + 1}
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.moduleSubHeder}>
              {mainData.Modules?.length > 0 && mainData.Modules[selectedModule] ? (
                    <p>
                      Module progress:{" "}
                      <span>{mainData.Modules[selectedModule]?.module_name}</span>
                    </p>
                  ) : (
                    <p>Module progress: <span>No Module Selected</span></p>
                  )}
            </div>
            <div className={style.progressBarSection}>
            <div
              className={style.progressBar}
              style={{
                
                backgroundColor: "#8E88903D",
                borderRadius: "2rem",
              }}
            >
              <div
                style={{
                  width: `${moduleProgress}%`,
                  backgroundColor: "#595BDB",
                  height: "12px",
                  transition: "width 0.5s ease-in-out",
                  borderRadius: "1rem",
                }}
              ></div>
            </div>
            <div className={style.progressBarCount}>
            <p>{moduleProgress}%</p>
            </div>
          </div>
            <div className={style.roundGraphSection}>
              {roundGraphData.length>0&&roundGraphData.map((data,i)=>{
                const color = getColorBasedOnPercentage(data.percentage_correct);
                const chartData = {
                  labels: ["Achieved", "Remaining"],
                  datasets: [
                    {
                      data: [data.percentage_correct, 100 - data.percentage_correct], 
                      backgroundColor: [color, "#EAEAEA"],   
                      borderWidth: 0,
                    },
                  ],
                };
      
                const options = {
                  cutout: '80%', 
                  plugins: {
                    legend: {
                      display: false, 
                    },
                    tooltip: {
                      enabled: false, 
                    },
                  },
                };
                return(
                <div key={i} className={style.roundGraphCard}>
                <div className={style.roundGraphCardHeder}>
                  <p>Topic {i+1}</p>
                </div>
                <div className={style.roundGraph}>
                <Doughnut data={chartData} options={options} />
                <div className={style.roundGraphData}>
                  <p>{data.correct_answers}</p>
                </div>
                </div>
  
                <div className={style.roundGraphCardBottom}>
                  <p>{data.percentage_correct}%</p>
                </div>
                </div>
                )
              })}
            </div>
        </div>
        <div className={style.learningSection}>
        <div className={style.learningSectionLeft}>
          <div className={style.learningHeader}>
            <p>Learning Pace</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForLP==="weekly"?style.selectedSubHeaderCard:""}`}
            onClick={() => handleLPDate("weekly")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForLP==="monthly"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleLPDate("monthly")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForLP==="yearly"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleLPDate("yearly")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
           <select
           value={learningPaceModID}
           onChange={(e)=>setLearningPaceModID(e.target.value)}
           >
            {moduleList&&moduleList.length>0&&moduleList.map((mod)=>(
              <option key={mod.module_id} value={mod.module_id}>{mod.module_name}</option>
            ))}
           </select>
           </div>
          </div>
          <div className={style.barGraph}>
          <Bar data={learningPaceChartData} options={learningPaceOptions} />
          </div>
        </div>
        <div className={style.learningSectionRight}>
        <div className={style.learningHeader}>
            <p>Learning Level</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.levelHeader}>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Current Score</h4>
            <p>(The mark scored in the last attempt)
            </p>
          </div>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Baseline score</h4>
            <p>(The mark scored in the first attempt)
            </p>
          </div>
          </div>
           <div className={style.learningSubHeaderRight}>
           <select
           value={learningLevelModID}
           onChange={(e)=>setLearningLevelModID(e.target.value)}
           >
            {moduleList&&moduleList.length>0&&moduleList.map((mod)=>(
              <option key={mod.module_id} value={mod.module_id}>{mod.module_name}</option>
            ))}
           </select>
           </div>
          </div>
          <div className={style.barGraph}>
          <Bar
            data={learningLevelGraphData}
            options={learningLevelOptions}
          />
          </div>
        </div>
        </div>

        <div className={style.assessmentSection}>
        <div className={style.assessmentSectionLeft}>
        <div className={style.learningHeader}>
            <p>Daily drill</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForDD==="week"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("week")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForDD==="month"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("month")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForDD==="year"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("year")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore}>
              <p>Today’s <br/>
              Score</p>
              <h4>12</h4>
            </div>
           </div>
          </div>
          <div className={style.lineGraph}>
          <Line
              data={lineChartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const index = context.dataIndex;
                        const score = ddCorrectScores[index];
                        const timeSec = ddTimeInSeconds[index];
                        const timeMin = Math.floor(timeSec / 60); // Convert time_sec to minutes
                        const remainingSec = timeSec % 60; // Get the remaining seconds if any

                        // Format time display
                        const timeDisplay = timeMin > 0 
                          ? `${timeMin} mins ${remainingSec} secs`
                          : `${timeSec} secs`;

                        return `You scored ${score} in ${timeDisplay}`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    type: 'category', 
                    title: {
                      display: true,
                      text: `Day `, 
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: false,
                    offset:true,
                    ticks: {
                      callback: function(value, index) {
                        return dailyLineLabels[index]; 
                      },
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />

          </div>
        </div>
        <div className={style.assessmentSectionRight}>
        <div className={style.learningHeader}>
            <p>Weekly Workout</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForWW==="week"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("week")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForWW==="month"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("month")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForWW==="year"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("year")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore} style={{backgroundColor:"#00DFC7"}}>
              <p>Week's <br/>
              Score</p>
              <h4>12</h4>
            </div>
           </div>
          </div>

          <div className={style.lineGraph}>
          <Line
              data={weeklyLineChart}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const index = context.dataIndex;
                        const score = wwCorrectScores[index];
                        const timeSec = wwTimeInSeconds[index];
                        const timeMin = Math.floor(timeSec / 60); // Convert time_sec to minutes
                        const remainingSec = timeSec % 60; // Get the remaining seconds if any

                        // Format time display
                        const timeDisplay = timeMin > 0 
                          ? `${timeMin} mins ${remainingSec} secs`
                          : `${timeSec} secs`;

                        return `You scored ${score} in ${timeDisplay}`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: `Day `, // Dynamic month abbreviation
                    },
                    grid: {
                      display: false,
                    },
                    offset:true,
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        </div>

        </div>
    </div>
  )
}

export default Performance